const CSS_CLASS = {
    UI: 'keditor-ui',
    UI_DRAGGING: 'keditor-ui-dragging',
    UI_HIDDEN: 'keditor-ui-hidden',
    UI_CUTTING: 'keditor-ui-cutting',
    UI_RESIZER: 'ui-resizable-resizer',
    
    SORTABLE: 'ui-sortable',
    RESIZABLE: 'ui-sortable',
    
    WRAPPER: 'keditor-wrapper',
    
    BTN: 'keditor-btn',
    BTN_GROUP: 'keditor-btn-group',
    BTN_PRIMARY: 'keditor-btn-primary',
    BTN_DEFAULT: 'keditor-btn-default',
    
    STATE_ACTIVE: 'active',
    STATE_OPENED: 'opened',
    STATE_SHOWED: 'showed',
    STATE_SELECTED: 'selected',
    STATE_DUPLICATED: 'duplicated',
    STATE_INITIALIZED: 'initialized',
    STATE_INITIALIZING: 'initializing',
    STATE_PREVIEWING: 'previewing',
    STATE_TOOLBAR_SHOWED: 'showed-keditor-toolbar',
    STATE_SIDEBAR_SHOWED: 'showed-keditor-sidebar',
    STATE_MODAL_OPENED: 'opened-modal',
    STATE_NOT_MATCHED: 'not-matched',
    STATE_HAS_FOOTER: 'has-footer',
    STATE_COPYING: 'copying',
    STATE_COPYING_COMPONENT: 'copying-component',
    STATE_COPYING_CONTAINER: 'copying-container',
    STATE_COPYING_SUB_CONTAINER: 'copying-sub-container',
    
    ADD_CONTENT: 'btn-add-content',
    PASTE_CONTENT: 'btn-paste-content',
    
    SIDEBAR: 'keditor-sidebar',
    SIDEBAR_HEADER: 'keditor-sidebar-header',
    SIDEBAR_BODY: 'keditor-sidebar-body',
    SIDEBAR_TITLE: 'keditor-sidebar-title',
    SIDEBAR_CLOSER: 'keditor-sidebar-closer',
    
    TOPBAR: 'keditor-topbar',
    TOPBAR_LEFT: 'keditor-topbar-left',
    TOPBAR_CENTER: 'keditor-topbar-center',
    TOPBAR_RIGHT: 'keditor-topbar-right',
    TOPBAR_BUTTON: 'keditor-topbar-btn',
    TOPBAR_TITLE: 'keditor-topbar-title',
    
    MODAL: 'keditor-modal',
    MODAL_COMPONENT: 'keditor-modal-component',
    MODAL_CONTAINER: 'keditor-modal-container',
    MODAL_HEADER: 'keditor-modal-header',
    MODAL_TITLE: 'keditor-modal-title',
    MODAL_BODY: 'keditor-modal-body',
    MODAL_FOOTER: 'keditor-modal-footer',
    MODAL_CLOSE: 'keditor-modal-close',
    MODAL_ADD: 'keditor-modal-add',
    
    SNIPPET: 'keditor-snippet',
    SNIPPET_INNER: 'keditor-snippet-inner',
    SNIPPET_TITLE: 'keditor-snippet-title',
    SNIPPET_PREVIEW: 'keditor-snippet-preview',
    SNIPPET_CONTAINER: 'keditor-snippet-container',
    SNIPPET_COMPONENT: 'keditor-snippet-component',
    SNIPPETS: 'keditor-snippets',
    SNIPPETS_FILTER: 'keditor-snippets-filter',
    SNIPPETS_FILTER_COMPONENT: 'keditor-snippets-filter-component',
    SNIPPETS_FILTER_CONTAINER: 'keditor-snippets-filter-container',
    SNIPPETS_FILTER_LABEL: 'keditor-snippets-filter-label',
    SNIPPETS_SEARCH: 'keditor-snippets-search',
    SNIPPETS_FILTER_WRAPPER: 'keditor-snippets-filter-wrapper',
    SNIPPETS_COMPONENT: 'keditor-snippets-component',
    SNIPPETS_WRAPPER: 'keditor-snippets-wrapper',
    
    TOOLBAR: 'keditor-toolbar',
    TOOLBAR_BOTTOM: 'keditor-toolbar-bottom',
    TOOLBAR_CONTENT_AREA: 'keditor-toolbar-content-area',
    TOOLBAR_CONTAINER: 'keditor-toolbar-container',
    TOOLBAR_CONTAINER_BOTTOM: 'keditor-toolbar-bottom-container',
    TOOLBAR_CONTAINER_CONTENT: 'keditor-toolbar-container-content',
    TOOLBAR_SUB_CONTAINER: 'keditor-toolbar-sub-container',
    TOOLBAR_SUB_CONTAINER_BOTTOM: 'keditor-toolbar-bottom-sub-container',
    TOOLBAR_SUB_CONTAINER_CONTENT: 'keditor-toolbar-sub-container-content',
    TOOLBAR_COMPONENT: 'keditor-toolbar-component',
    TOOLBAR_COMPONENT_BOTTOM: 'keditor-toolbar-bottom-component',
    
    SETTING: 'keditor-setting',
    SETTING_FORM: 'keditor-setting-form',
    SETTING_FORM_LOADING: 'keditor-setting-form-loading',
    
    CONTENT_AREA: 'keditor-content-area',
    CONTENT_AREA_INNER: 'keditor-content-area-inner',
    CONTENT_AREAS: 'keditor-content-areas',
    CONTENT_AREAS_WRAPPER: 'keditor-content-areas-wrapper',
    
    IFRAME: 'keditor-iframe',
    IFRAME_BODY: 'keditor-iframe-body',
    IFRAME_WRAPPER: 'keditor-iframe-wrapper',
    IFRAME_WIDTH_SWITCHER: 'keditor-iframe-width-switcher',
    IFRAME_COVER_WRAPPER: 'keditor-iframe-cover-wrapper',
    IFRAME_COVER_WRAPPER_FAKE: 'keditor-iframe-cover-wrapper-fake',
    IFRAME_COVER: 'keditor-iframe-cover',
    IFRAME_COVER_HIDDEN: 'hidden-cover',
    
    COMPONENT: 'keditor-component',
    COMPONENT_MOVE: 'btn-component-move',
    COMPONENT_MOVE_UP: 'btn-component-move-up',
    COMPONENT_MOVE_DOWN: 'btn-component-move-down',
    COMPONENT_SETTING: 'btn-component-setting',
    COMPONENT_DUPLICATE: 'btn-component-duplicate',
    COMPONENT_COPY: 'btn-component-copy',
    COMPONENT_CUT: 'btn-component-cut',
    COMPONENT_DELETE: 'btn-component-delete',
    COMPONENT_CONTENT: 'keditor-component-content',
    COMPONENT_EXISTING: 'existing-component',
    
    CONTAINER: 'keditor-container',
    CONTAINER_MOVE: 'btn-container-move',
    CONTAINER_MOVE_UP: 'btn-container-move-up',
    CONTAINER_MOVE_DOWN: 'btn-container-move-down',
    CONTAINER_DUPLICATE: 'btn-container-duplicate',
    CONTAINER_COPY: 'btn-container-copy',
    CONTAINER_CUT: 'btn-container-cut',
    CONTAINER_DELETE: 'btn-container-delete',
    CONTAINER_SETTING: 'btn-container-setting',
    CONTAINER_INNER: 'keditor-container-inner',
    CONTAINER_CONTENT: 'keditor-container-content',
    CONTAINER_CONTENT_INNER: 'keditor-container-content-inner',
    
    SUB_CONTAINER: 'keditor-sub-container',
    SUB_CONTAINER_CONTENT: 'keditor-sub-container-content',
    
    PREVIEW_AREA: 'keditor-preview-area',
};

export default CSS_CLASS;
