const ICON = {
    ADD_CONTENT: '<i class="fa fa-plus"></i>',
    PASTE_CONTENT: '<i class="fa fa-paste"></i>',
    
    MOVE_CONTAINER: '<i class="fa fa-arrows-v"></i>',
    MOVE_COMPONENT: '<i class="fa fa-arrows"></i>',
    
    MOVE_UP_CONTAINER: '<i class="fa fa-chevron-up"></i>',
    MOVE_UP_COMPONENT: '<i class="fa fa-chevron-up"></i>',
    
    MOVE_DOWN_CONTAINER: '<i class="fa fa-chevron-down"></i>',
    MOVE_DOWN_COMPONENT: '<i class="fa fa-chevron-down"></i>',
    
    SETTING_CONTAINER: '<i class="fa fa-cog"></i>',
    SETTING_COMPONENT: '<i class="fa fa-cog"></i>',
    
    DUPLICATE_CONTAINER: '<i class="fa fa-files-o"></i>',
    DUPLICATE_COMPONENT: '<i class="fa fa-files-o"></i>',
    
    COPY_CONTAINER: '<i class="fa fa-files-o"></i>',
    COPY_COMPONENT: '<i class="fa fa-files-o"></i>',
    
    CUT_CONTAINER: '<i class="fa fa-cut"></i>',
    CUT_COMPONENT: '<i class="fa fa-cut"></i>',
    
    DELETE_CONTAINER: '<i class="fa fa-trash-o"></i>',
    DELETE_COMPONENT: '<i class="fa fa-trash-o"></i>',
    
    DEVICE_MOBILE: '<i class="fa fa-fw fa-mobile"></i>',
    DEVICE_TABLET: '<i class="fa fa-fw fa-tablet"></i>',
    DEVICE_LAPTOP: '<i class="fa fa-fw fa-laptop"></i>',
    DEVICE_DESKTOP: '<i class="fa fa-fw fa-desktop"></i>',
    
    PREVIEW_ON: '<i class="fa fa-fw fa-eye"></i>',
    PREVIEW_OFF: '<i class="fa fa-fw fa-eye-slash"></i>',
    
    FULLSCREEN_ON: '<i class="fa fa-fw fa-compress"></i>',
    FULLSCREEN_OFF: '<i class="fa fa-fw fa-expand"></i>',
    
    SAVE: '<i class="fa fa-fw fa-save"></i>',
    
    ADD: '<i class="fa fa-plus"></i>'
};

export default ICON;
